<script lang="ts" setup>
  import { useBreakpoints } from '$storefront';
  import { route, $post, useShared, $delete } from '@plenny/visitor';
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';

  const props = defineProps({
    product: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: false,
      default: 'h2',
    },
    horizontal: {
      type: Boolean,
    },
  });
  const shared = useShared();
  const breakpoints = useBreakpoints();

  const target = computed(() => {
    return route('web.product.show', { slug: props.product.slug });
  });

  const isCompared = computed(() => {
    if (props.product.variant_id && shared.value.comparator) {
      return shared.value.comparator.map((c) => c.variant_id).includes(props.product.variant_id);
    } else {
      return false;
    }
  });

  const compareTitle = computed(() => {
    return isCompared.value ? trans('Usuń z porównania') : trans('Dodaj do porównania');
  });

  const hasListAttributes = computed(() => {
    return props.product.list_attributes && props.product.list_attributes.length > 0;
  });

  function toggleComparator() {
    if (isCompared.value) {
      $delete(route('api.v1.web.comparator.destroy', { 'variant_id': props.product.variant_id }));
    } else {
      $post(route('api.v1.web.comparator.store', { 'variant_id': props.product.variant_id }));
    }
  }
</script>
<template>
  <article :class="{ 'product-card--horizontal': horizontal, 'has-labels': product.is_new || product.discount_value }" class="product-card" tabindex="0" @click="$redirect(target)">
    <div class="product-card__image">
      <MediaPicture :photo="product.photo" sizes="530x530 2x, 265x265" />
    </div>
    <div class="product-card__content">
      <div class="product-card__info">
        <header>
          <component :is="header" class="product-card__title">
            <VisitorLink :href="target">
              {{ product.title }}
            </VisitorLink>
          </component>
        </header>
        <dl v-if="hasListAttributes" v-show="horizontal" class="product-card__description-list">
          <div v-if="product.brand_name" class="product-card__details-column">
            <dt class="product-card__description-term">{{ $t('Marka') + ': ' }}</dt>
            <dd class="product-card__description-details">{{ product.brand_name }}</dd>
          </div>
          <div v-if="product.ean" class="product-card__details-column">
            <dt class="product-card__description-term">{{ $t('EAN') + ': ' }}</dt>
            <dd class="product-card__description-details">{{ product.ean }}</dd>
          </div>
          <div v-for="attribute in product.list_attributes.slice(0, 9)" :key="attribute.name" class="product-card__details-column">
            <dt class="product-card__description-term">{{ attribute.name }}:</dt>
            <dd :title="attribute.value" class="product-card__description-details">{{ attribute.value }}</dd>
          </div>
        </dl>
      </div>
      <div class="product-card__actions">
        <div class="product-card__actions-top">
          <div class="product-card__price-icons">
            <div class="product-card__price">
              <SfProductPrice :price="product" class="product-card__product-price" />
              <div class="product-card__icons">
                <slot name="icons">
                  <SfWishlistButton :variant="product.variant_id" class="product-card__wishlist" tabindex="0" />
                  <SfButton :class="{'compared': isCompared}" :title="compareTitle" :variant="product.variant_id" class="product-card__compare" square tabindex="0" transparent @click.stop="toggleComparator">
                    <IconArrowDownUp />
                  </SfButton>
                </slot>
              </div>
            </div>
            <SfProductOmnibus :price="product" class="product-card__omnibus" />
          </div>
        </div>
        <div class="product-card__buttons">
          <SfCartButton :product="product">
            <SfButton :square="!breakpoints.lg" class="product-card__buy-btn product-card__buy-btn--static" primary>
              <template #after>
                <IconBasket class="product-card__buy-btn-icon" />
              </template>
              <template #default>
                {{ $t('Dodaj do koszyka') }}
              </template>
            </SfButton>
          </SfCartButton>
        </div>
      </div>
    </div>
    <div class="product-card__labels">
      <SfProductTag :visible="product.is_new" tagType="novelty">
        {{ $t('Nowość') }}
      </SfProductTag>
      <SfProductTag :visible="+product.price_gross !== +product.regular_gross" tagType="discount">
        {{ $t('Promocja') }}
      </SfProductTag>
    </div>
  </article>
</template>
<style lang="scss" scoped>
  .product-card {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 1.5rem;
    padding: 1.5rem;
    background-color: var(--color-primary-white);
    cursor: pointer;
    box-shadow: none;
    transition-property: box-shadow;
    transition-duration: var(--transition-primary-duration);
    transition-timing-function: var(--transition-primary-timing-function);

    @media(hover: hover) {
      &:hover {
        box-shadow: var(--box-shadow-primary);
      }
    }

    &__image {
      position: relative;
      width: 100%;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: var(--primary-color);
      }

      :deep(picture) {
        display: inline-block;
        width: 100%;

        img {
          aspect-ratio: 1;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      gap: 1rem;
    }

    &__title {
      display: -webkit-box;
      font-size: 1rem;
      font-weight: var(--font-weight-500);
      line-height: 1.5;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    &__icons {
      position: absolute;
      top: 1.25rem;
      right: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition-property: opacity;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
      background-color: var(--color-white);
    }

    &__description-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0 2rem;
      font-size: 0.75rem;
      line-height: 1.5;
      color: var(--color-main-black);

      .product-card__details-column {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      .product-card__description-term {
        display: inline;
        margin-right: 0.25rem;
      }

      .product-card__description-details {
        display: inline;
        font-weight: var(--font-weight-500);
      }
    }

    &__wishlist {
      --sf-btn-font-size-icon-sm: 1.5rem;
    }

    &__wishlist,
    .product-card__compare {
      --sf-btn-padding-y: 0.375rem;
      --sf-btn-padding-y-sm: 0.375rem;

      svg {
        font-size: 1.5rem;
      }
    }

    &__compare.compared {
      color: var(--primary-color);
    }

    &__labels {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__details-wrapper,
    &__brand-sku,
    &__more-btn {
      display: none;
    }

    &__product-price :deep(.price__current) {
      color: var(--color-theme-blue);
    }

    &__buy-btn {
      width: 100%;
      --sf-btn-gap: 0.875rem;
    }

    &__buy-btn--dynamic {
      position: absolute;
      right: 1rem;
      bottom: 0;
      opacity: 0;
      transition-property: opacity, bottom, background-color;

      &:focus {
        bottom: 1rem;
        opacity: 1;
      }
    }

    &__buy-btn-icon {
      font-size: 1.25rem;
    }

    &:hover,
    &:focus {
      .product-card__buy-btn--dynamic,
      .product-card__wishlist :deep(.wishlist-button__favorite),
      .product-card__compare {
        opacity: 1;
      }

      .product-card__buy-btn--dynamic {
        bottom: 1rem;
      }
    }

    @media(min-width: 993px) {

      &--horizontal {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        gap: 1.5rem;
        padding: 1.5rem;

        > .product-card__image {
          width: unset;

          &::after {
            display: none;
          }

          > :deep(picture) {
            width: 265px;
          }
        }

        > .product-card__content {
          display: grid;
          grid-template-columns:  1fr 240px;
          gap: 1rem;

          > .product-card__info {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          > .product-card__actions {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1.5rem;

            > .product-card__actions-top {
              display: flex;
              flex-direction: row;
              gap: 1.5rem;
              justify-content: flex-end;

              > .product-card__price-icons {
                > .product-card__price {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  gap: 1.5rem;

                  > .product-card__product-price {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    > :deep(.price__current) {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    }
                  }

                  > .product-card__icons {
                    position: sticky;
                    order: -1;
                    flex-direction: row;
                    gap: 0.5rem;
                  }

                  > .product-card__product-price {
                    --sf-product-price-current-font-size: 1.5rem;
                    --sf-product-price-current-font-line-height: 1.4;
                    --sf-product-price-per-font-size: 0.875rem;
                    --sf-omnibus-font-size: 0.75rem;
                  }
                }

                > .product-card__omnibus {
                  padding-top: 0.5rem;
                  text-align: right;
                }
              }
            }

            > .product-card__buttons {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              > .product-card__more-btn {
                display: inline-flex;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 993px) and (max-width: 1400px) {
    .product-card {
      &--horizontal {
        gap: 1.5rem;
        padding: 1.5rem;

        > .product-card__image {
          width: unset;

          > :deep(picture) {
            width: 200px;
          }
        }

        > .product-card__content {
          grid-template-columns:  1fr;
          gap: 1.5rem;

          > .product-card__actions > .product-card__actions-top {
            justify-content: flex-end;

            > .product-card__price-icons {
              > .product-card__price {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                > .product-card__product-price {
                  padding-right: 0;
                }

                > .product-card__icons {
                  position: static;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {

    .product-card {
      padding: 0;
      gap: 1rem;
      --sf-product-price-catalog-font-size: 0.75rem;
      --sf-product-price-current-font-size: 1rem;

      &__content {
        gap: 0.625rem;
      }

      &__actions-top,
      &__info,
      &__image {
        padding: 0 0.75rem;
      }

      &__image::after {
        margin: 0 0.75rem;
      }

      &__title {
        font-size: 0.75rem;
      }

      &__buy-btn {
        --sf-btn-font-size: 0.75rem;
        --sf-btn-font-weight: var(--font-weight-500);
        gap: var(--sf-btn-gap);
      }

      &__buy-btn-icon {
        font-size: 1rem;
      }

      &__wishlist,
      .product-card__compare {
        --sf-btn-padding-y: 0.5rem;
        --sf-btn-padding-y-sm: 0.5rem;
        opacity: 1;
      }

      &__icons {
        top: 0.75rem;
        right: 0.75rem;
      }

      &__wishlist :deep(.wishlist-button__favorite svg),
      .product-card__compare {
        font-size: 1.25rem;
      }

      &__actions {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
      }

      &__labels {
        left: 0.75rem;
        top: 0.75rem;
        flex-direction: column;
        gap: 0.5rem;
      }

      &__description-list {
        font-size: 0.625rem;
        margin-top: 0.625rem;
      }

      &--horizontal > .product-card__content > .product-card__actions > .product-card__actions-top {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.875rem;

        > .product-card__brand-sku {
          display: unset;

          > .product-card__description-list {
            margin-top: 0;
          }
        }
      }
    }
  }
</style>

